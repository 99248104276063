import * as ToastPrimitive from '@radix-ui/react-toast';

import { IconX } from '@/icons/IconX';

export function ToastContent({ description, title }: IToastContentProps) {
  return (
    <div className='flex w-0 flex-1 items-center py-4 pl-5'>
      <div className='radix w-full'>
        {title && (
          <ToastPrimitive.Title className='text-sm font-medium text-gray-100'>
            {title}
          </ToastPrimitive.Title>
        )}

        {description && (
          <ToastPrimitive.Description className='mt-1 text-sm text-gray-400'>
            {description}
          </ToastPrimitive.Description>
        )}
      </div>

      <ToastPrimitive.Close className='mx-4'>
        <IconX className='h-5 stroke-white' />
      </ToastPrimitive.Close>
    </div>
  );
}
