import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const MAX_RETRIES = 5;
const RETRY_DELAY = 3000; // 3 seconds

const createWSClient = () => {
  let retries = 0;
  let client: ReturnType<typeof createClient>;

  const initClient = () => {
    client = createClient({
      url:
        process.env.NEXT_PUBLIC_SUBSCRIPTION_URL ||
        'ws://localhost:3333/graphql',
      retryAttempts: MAX_RETRIES,
      on: {
        connected: () => {
          console.log('WebSocket connected');
          retries = 0;
        },
        error: (error: any) => {
          console.error('WebSocket error', error.message);
          if (error.message === 'Socket closed') {
            if (retries < MAX_RETRIES) {
              retries++;
              console.log(
                `Attempting to reconnect (${retries}/${MAX_RETRIES})...`,
              );
              setTimeout(() => {
                client.dispose();
                initClient();
              }, RETRY_DELAY);
            } else {
              console.error('Max retries reached. Unable to reconnect.');
            }
          }
        },
        closed: () => {
          console.log('WebSocket connection closed');
        },
      },
    });
  };

  initClient();
  return client!;
};

const wsClient = createWSClient();

export const wsLink = new GraphQLWsLink(wsClient);
