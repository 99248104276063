import { createContext, useMemo } from 'react';

import { ListToasts } from '@/app/components/ListToasts';
import { useToastStore } from '@/stores/toastStore';

interface IToastContext {
  addToast: IToastStore['addToast'];
}

export const ToastContext = createContext<IToastContext>({} as IToastContext);

interface IToastProviderProps {
  children: React.ReactNode;
}

export function ToastProvider({ children }: IToastProviderProps) {
  const addToast = useToastStore(state => state.addToast);

  const toastValue = useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={toastValue}>
      {children}

      <ListToasts />
    </ToastContext.Provider>
  );
}
