'use client';

import { ApolloProviderWrapper } from './apolloProvider';
import NextAuthSessionProvider from './sessionProvider';
import { ToastProvider } from './toastProvider';

function AppProvider({ children }: { children?: React.ReactNode }) {
  return (
    <NextAuthSessionProvider>
      <ApolloProviderWrapper>
        <ToastProvider>{children}</ToastProvider>
      </ApolloProviderWrapper>
    </NextAuthSessionProvider>
  );
}
export default AppProvider;
