export const apolloPossibleTypes = {
  TSectionElementsType: [
    'ElementField',
    'ElementContract',
    'ElementPayment',
    'ElementText',
    'ElementSubmit',
    'ElementImage',
    'ElementVideo',
  ],
};
