import * as ToastPrimitive from '@radix-ui/react-toast';

import { useToastStore } from '@/stores/toastStore';

import { IconType } from '../constants';

import { className } from './styles';

export function ToastRoot({
  children,
  type = 'warning',
  id,
  duration,
}: IToastRootProps) {
  const removeToast = useToastStore(state => state.removeToast);

  const onOpenChange = (open: boolean) => {
    if (!open) {
      removeToast(id);
    }
  };

  return (
    <ToastPrimitive.Provider duration={duration}>
      <ToastPrimitive.Root className={className()} onOpenChange={onOpenChange}>
        <div className='flex'>
          <span className='-mr-3 ml-3 mt-3.5'>{IconType[type]}</span>
          {children}
        </div>
      </ToastPrimitive.Root>

      <ToastPrimitive.Viewport />
    </ToastPrimitive.Provider>
  );
}
