import { useToastStore } from '@/stores/toastStore';

import ToastComponent from '../Toast';

export function ListToasts() {
  const toasts = useToastStore(state => state.toasts);

  return toasts.map((toast, index) => (
    <ToastComponent.Root
      key={index}
      id={toast._id}
      type={toast.type}
      duration={toast.duration}
    >
      <ToastComponent.Content
        title={toast.title}
        description={toast.description}
      />
    </ToastComponent.Root>
  ));
}
