import { gql } from '@apollo/client';

export const UPDATE_DRAFT_DOCUMENT_TEMPLATE = /* GraphQL */ `
  mutation UPDATE_DRAFT_DOCUMENT_TEMPLATE(
    $input: UpdateDraftDocumentTemplateInput!
    $id: String!
  ) {
    updateDraftDocumentTemplate(input: $input, id: $id)
  }
`;

export const CREATE_DRAFT_DOCUMENT_TEMPLATE = /* GraphQL */ `
  mutation CREATE_DRAFT_DOCUMENT_TEMPLATE(
    $input: CreateDraftDocumentTemplateInput!
  ) {
    createDraftDocumentTemplate(input: $input) {
      _id
    }
  }
`;

export const FRAGMENT_DOCUMENT_INTERACTIONS = /* GraphQL */ `
  fragment DocumentInteractions on TInteractionsType {
    ... on InteractionField {
      _id
      elementId
      type
      value
    }
    ... on InteractionContract {
      _id
      elementId
      type
      contractId
    }
    ... on InteractionPayment {
      _id
      elementId
      organizationPaymentId
    }
  }
`;

export const FRAGMENT_DOCUMENT_ELEMENTS = /* GraphQL */ `
  fragment DocumentElements on TSectionElementsType {
    ... on ElementField {
      _id
      type
      name
      fieldType
      label
      description
      descriptionEnabled
      helpText
      helpTextEnabled
      placeholder
      required
      disabled
      readonly
      defaultValue
      options {
        id
        title
      }
      layouts {
        labelColor
        widthType
        maxWidth
        viewport
      }
    }
    ... on ElementText {
      _id
      type
      value
    }
    ... on ElementContract {
      _id
      type
      templateContractId
      contractId
      required
      buttonBackgroundColor
      alignment
      color
      backgroundColor
      layouts {
        fillColumn
        viewport
      }
    }
    ... on ElementPayment {
      _id
      type
      productId
      required
      backgroundColor
      buttonColor
      buttonText
      buttonTextColor
      textColor
      layouts {
        maxWidth
        widthType
        viewport
      }
      alignment
    }
    ... on ElementSubmit {
      _id
      type
      text
      alignment
      fillColumn
      backgroundColor
      color
    }
    ... on ElementImage {
      _id
      type
      assetId
      layouts {
        maxWidth
        maxHeight
        widthType
        viewport
      }
      alignment
    }

    ... on ElementVideo {
      _id
      type
      assetId
      layouts {
        maxWidth
        widthType
        viewport
      }
      alignment
    }
  }
`;

export const FRAGMENT_DOCUMENT_TEMPLATE = /* GraphQL */ `
  fragment DocumentTemplate on DocumentTemplate {
    _id
    zupId
    draftDocumentTemplateId
    version
    layouts {
      backgroundColor
      containerBehavior
      padding
      gap
      containerBackgroundColor
      viewport
    }
    elementSections {
      _id
      name
      layouts {
        backgroundColor
        backgroundImage
        gap
        padding
        viewport
      }
      columns {
        order
        _id
        name
        layouts {
          backgroundColor
          backgroundImage
          gap
          padding
          viewport
          flexDirection
        }
        elements {
          ...DocumentElements
        }
      }
    }
  }
`;

export const GET_DRAFT_DOCUMENT_TEMPLATE = /* GraphQL */ `
  query GET_DRAFT_DOCUMENT_TEMPLATE($id: String!) {
    getDraftDocumentTemplate(id: $id) {
      _id
      documentTemplateId
      startStepId
      version
      zupId
      elements {
        ...DocumentElements
      }
    }
  }
`;

export const GET_DOCUMENT_TEMPLATE = /* GraphQL */ `
  ${FRAGMENT_DOCUMENT_ELEMENTS}
  ${FRAGMENT_DOCUMENT_TEMPLATE}

  query GET_DOCUMENT_TEMPLATE($id: String!) {
    getDocumentTemplate(id: $id) {
      ...DocumentTemplate
    }
  }
`;

export const GET_DOCUMENT_TEMPLATE_FROM_ZUP_TEMPLATE = /* GraphQL */ `
  ${FRAGMENT_DOCUMENT_ELEMENTS}
  ${FRAGMENT_DOCUMENT_TEMPLATE}

  query GET_DOCUMENT_TEMPLATE($id: String!, $organizationId: String!) {
    getDocumentTemplateFromZupTemplate(
      id: $id
      organizationId: $organizationId
    ) {
      ...DocumentTemplate
    }
  }
`;

export const APPLY_CHANGES_DOCUMENT_TEMPLATE = /* GraphQL */ `
  ${FRAGMENT_DOCUMENT_ELEMENTS}

  mutation APPLY_CHANGES_DOCUMENT_TEMPLATE(
    $input: UpdateDocumentTemplateInput!
    $id: String!
  ) {
    applyChangesDocumentTemplate(input: $input, id: $id) {
      _id
      draftDocumentTemplateId
      version
      layouts {
        backgroundColor
        containerBehavior
        padding
        gap
        containerBackgroundColor
        viewport
      }
      elementSections {
        _id
        name
        layouts {
          backgroundColor
          backgroundImage
          gap
          padding
          viewport
        }
        columns {
          order
          _id
          name
          layouts {
            backgroundColor
            backgroundImage
            gap
            padding
            flexDirection
            viewport
          }
          elements {
            ...DocumentElements
          }
        }
      }
    }
  }
`;

export const CREATE_DOCUMENT = /* GraphQL */ `
  ${FRAGMENT_DOCUMENT_INTERACTIONS}

  mutation CREATE_DOCUMENT(
    $documentTemplateId: String!
    $interactions: String
  ) {
    createDocument(
      documentTemplateId: $documentTemplateId
      interactions: $interactions
    ) {
      _id
      documentTemplateId
      documentTemplateVersion
      interactions {
        ...DocumentInteractions
      }
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT($documentId: String!, $interactions: String) {
    updateDocument(documentId: $documentId, interactions: $interactions) {
      _id
    }
  }
`;

export const UPDATE_ITEM_TRAIL = /* GraphQL */ `
  mutation UPDATE_ITEM_TRAIL($input: ItemUpdateInput!, $id: String!) {
    updateItem(input: $input, id: $id) {
      _id
      zupId
      documentId
      name
      currentStepId
      completedAt
      trail {
        stepId
        documentId
        movedIn
        movedOut
      }
      guest {
        _id
        name
        email
        avatar
        guestAccount {
          phoneNumber
        }
      }
    }
  }
`;
