export enum CustomEventEnum {
  ACCESS_CODE_CREATED = 'access-code-created',
  USER_AUTHENTICATED = 'user-authenticated',
  AUTH_CHANGE_EMAIL = 'auth-change-email',
  GUEST_AUTH_CHANGE_FORM = 'guest-auth-change-form',
  CLOSE_SLIDE_OVER = 'close-slide-over',
  CONTRACT_SIGNED = 'contract-signed',
  APPLY_CONTRACT_CHANGES = 'apply-contract-changes',
  DISCARD_CONTRACT_CHANGES = 'discard-contract-changes',
  SESSION_EXPIRED = 'session-expired',
}
