import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.ts\",\"import\":\"Dancing_Script\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":\"400\",\"variable\":\"--font-dancing\"}],\"variableName\":\"dancingScript\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vime/core/themes/default.css");
;
import(/* webpackMode: "eager", webpackExports: ["GTMAnalytics"] */ "/vercel/path0/src/app/components/GTMAnalytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageDetector"] */ "/vercel/path0/src/app/components/LanguageDetector/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/vercel/path0/src/app/components/WebVitals/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/providers/appProvider.tsx");
