export function GTMNoScript() {
  return (
    <noscript>
      <iframe
        title='gtm-noscript'
        src='https://www.googletagmanager.com/ns.html?id=GTM-58MRGGZ5'
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
}
