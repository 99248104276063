import { create } from 'zustand';

import { getRandomUUID } from '@/utils/GetRandomUUID';

export const useToastStore = create<IToastStore>(set => ({
  toasts: [],

  addToast: toast =>
    set(state => ({
      toasts: [
        ...state.toasts,
        { ...toast, _id: getRandomUUID(), duration: 3000 },
      ],
    })),
  removeToast: id =>
    set(state => ({
      toasts: state.toasts.filter(toast => toast._id !== id),
    })),
}));
