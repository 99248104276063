import { ApolloLink } from '@apollo/client';
import { trace, SpanKind } from '@opentelemetry/api';

export const createSpanLink = new ApolloLink((operation, forward) => {
  const tracer = trace.getTracer('@apollo/client');
  const span = tracer.startSpan(`gql.${operation.operationName}`, {
    startTime: operation.getContext().start,
    attributes: {
      operationName: operation.operationName,
      query: operation.query.loc?.source.body,
      variables: operation.variables
        ? JSON.stringify(operation.variables, null, 2)
        : undefined,
    },
    kind: SpanKind.INTERNAL, // 0: Internal, 1: Server, 2: Client, 3: Producer, 4: Consumer
  });
  operation.setContext({ span });

  return forward(operation).map(data => {
    span.end();
    return data;
  });
});
