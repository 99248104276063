/* eslint-disable default-param-last */
import { FieldPolicy } from '@apollo/client';

export const getItems: FieldPolicy = {
  keyArgs: ['zupId', 'externalZupId', 'filter'],
  merge(
    existing: { data: []; flowSettingId?: string } = { data: [] },
    incoming: { data: []; pagination: any; flowSettingId: string },
    {
      args: {
        pagination: { page, limit },
      },
    }: any,
  ) {
    const offset = (page - 1) * limit;

    const merged = existing ? existing.data.slice(0) : [];
    for (let i = 0; i < incoming.data.length; ++i) {
      merged[offset + i] = incoming.data[i];
    }

    return { ...incoming, data: merged, pagination: incoming.pagination };
  },
};
