import { tv } from 'tailwind-variants';

export const className = tv({
  base: `radix-swipe-cancel:ease-in-out fixed inset-x-4 bottom-4 z-[110] w-auto rounded-lg bg-gray-800 shadow-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75
  radix-state-closed:animate-toast-hide
  radix-state-open:animate-toast-slide-in-bottom radix-swipe-cancel:translate-x-0
  radix-swipe-cancel:duration-200
  radix-swipe-direction-down:translate-y-radix-toast-swipe-move-y
  radix-swipe-direction-down:radix-swipe-end:animate-toast-swipe-out-y
  radix-swipe-direction-right:translate-x-radix-toast-swipe-move-x
  radix-swipe-direction-right:radix-swipe-end:animate-toast-swipe-out-x
  md:bottom-auto md:left-auto md:right-4
  md:top-4 md:w-full md:max-w-sm md:radix-state-open:animate-toast-slide-in-right
  `,
});
