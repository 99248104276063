type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const pageview = (url: string) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return null;
  }

  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
    return;
  }

  console.warn('GTM event not handled', {
    event: 'pageview',
    page: url,
  });
};

export const pushEvent = (event: string, data?: Record<string, any>) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return null;
  }

  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ event, ...data });
    return;
  }

  console.warn('GTM event not handled', {
    event,
    data,
  });
};
