'use client';

import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { getCookie } from 'cookies-next';
import { PropsWithChildren, useMemo } from 'react';

import {
  apolloFragments,
  apolloPossibleTypes,
  apolloTypePolicies,
  graphqlSplittedLink,
} from '@/apollo';
import { subdomainCookieName } from '@/constants';
import { extractSubdomain } from '@/utils/ExtractSubdomain';

const authMiddleware = setContext(async (operation, { headers }) => {
  const response = await fetch('/api/auth/token')
    .then(res => res.json())
    .catch(() => null);

  let subdomain;

  if (typeof window !== 'undefined') {
    subdomain =
      getCookie(subdomainCookieName) || extractSubdomain(window.location.host);
  }

  const authorization = response?.token
    ? `Bearer ${response.token}`
    : undefined;

  return {
    headers: {
      ...headers,
      authorization,
      'Apollo-Require-Preflight': 'true',
      subdomain,
    },
  };
});

export const ApolloProviderWrapper = ({ children }: PropsWithChildren) => {
  const client = useMemo(
    () =>
      new ApolloClient({
        link: from([authMiddleware, graphqlSplittedLink]),
        ssrMode: typeof window === 'undefined',
        cache: new InMemoryCache({
          fragments: createFragmentRegistry(apolloFragments),
          possibleTypes: apolloPossibleTypes,
          typePolicies: apolloTypePolicies,
        }),
      }),
    [],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
