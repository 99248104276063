import { getAssets } from './getAssetsTypePolicy';
import { getItems } from './getItemsTypePolicy';

export const apolloTypePolicies = {
  Query: {
    fields: {
      getAssets,
      getItems,
    },
  },
};
