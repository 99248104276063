'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import { GTMNoScript } from '@/app/components/GTMAnalytics/GTMNoScript';
import { GTMScript } from '@/app/components/GTMAnalytics/GTMScript';
import { pageview } from '@/utils/GTMFunctions';

export function GTMAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (pathname) {
      pageview(pathname);
    }
  }, [pathname, searchParams]);

  if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
    return null;
  }

  return (
    <>
      <GTMScript />
      <GTMNoScript />
    </>
  );
}
