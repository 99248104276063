import { ReactElement } from 'react';

import { AlertCircleIcon } from '@/icons/AlertCircleIcon';
import { SuccessCircleIcon } from '@/icons/SucessCircleIcon';
import { XCircleIcon } from '@/icons/XCircleIcon';

export const IconType: Record<TToastType, ReactElement> = {
  error: <XCircleIcon className='h-6 w-6 stroke-red-500' />,
  success: <SuccessCircleIcon className='h-6 w-6 stroke-green-500' />,
  warning: <AlertCircleIcon className='h-6 w-6  stroke-yellow-500' />,
};
