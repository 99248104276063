type EventName = string;
type Listener = (event: Event) => void;

function dispatchEvent(event: Event): void {
  window.dispatchEvent(event);
}

function subscribe(eventName: EventName, listener: Listener): void {
  window.addEventListener(eventName, listener);
}

function unsubscribe(eventName: EventName, listener: Listener): void {
  window.removeEventListener(eventName, listener);
}

function publish<T>(eventName: EventName, data: T): CustomEvent<T> {
  const event = new CustomEvent<T>(eventName, { detail: data });

  dispatchEvent(event);

  return event;
}

export { dispatchEvent, publish, subscribe, unsubscribe };
