'use client';

import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';

interface Props {
  children?: React.ReactNode;
  client?: Session | null | undefined;
}

export default function NextAuthSessionProvider({ children, client }: Props) {
  return (
    <SessionProvider session={client} refetchOnWindowFocus>
      {children}
    </SessionProvider>
  );
}
