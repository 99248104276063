import * as ToastPrimitive from '@radix-ui/react-toast';

import { IconX } from '@/icons/IconX';

export function ToastClose() {
  return (
    <div className='mr-3 flex justify-center'>
      <div className='flex flex-col space-y-1'>
        <div className='mt-3'>
          <ToastPrimitive.Close className='flex items-center justify-center  rounded-lg border border-transparent text-sm font-medium text-gray-100 hover:bg-gray-900 focus:z-10 focus:outline-none focus-visible:ring focus-visible:ring-purpleBlue-600 focus-visible:ring-opacity-75'>
            <IconX className='stroke-white' />
          </ToastPrimitive.Close>
        </div>
      </div>
    </div>
  );
}
