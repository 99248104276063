/* eslint-disable default-param-last */
import { FieldPolicy } from '@apollo/client';

export const getAssets: FieldPolicy = {
  keyArgs: ['filter'],
  merge(
    existing: { data: [] } = { data: [] },
    incoming: { data: []; pagination: any },
    { args }: any,
  ) {
    const page = args?.pagination?.page ?? 1;
    const limit = args?.pagination?.limit ?? 10;

    const offset = (page - 1) * limit;

    const merged = existing ? existing.data.slice(0) : [];
    for (let i = 0; i < incoming.data.length; ++i) {
      merged[offset + i] = incoming.data[i];
    }

    return { ...incoming, data: merged, pagination: incoming.pagination };
  },
};
