import { iconClasses } from '../styles';

function XCircleIcon({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <circle
        cx='12'
        cy='12.0017'
        r='6.50271'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.86003 4.99707C1.04381 8.88582 1.04381 15.1142 4.86003 19.0029'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.14 4.99707C22.9562 8.88582 22.9562 15.1142 19.14 19.0029'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.4994 10.499L13.5006 13.5003'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5006 10.499L10.4994 13.5003'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export { XCircleIcon };
