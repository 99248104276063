import { from, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { graphqlErrorHandler } from './errorHandler';
import { httpLink } from './httpLink';
import { createSpanLink } from './spanLink';
import { wsLink } from './wsClient';

export const graphqlSplittedLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([createSpanLink, graphqlErrorHandler, httpLink]),
);
