import { onError } from '@apollo/client/link/error';
import { SpanStatusCode } from '@opentelemetry/api';

import { ErrorTypeEnum } from '@/data/enums';
import { publish } from '@/utils/CustomEvent';
import { CustomEventEnum } from '@/utils/CustomEvent/enums';

export const graphqlErrorHandler = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const { span } = operation.getContext();
    span.setStatus({ code: SpanStatusCode.ERROR });

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        span.recordException({
          message: `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            2,
          )}, Path: ${JSON.stringify(path, null, 2)}`,
        });

        if (message.includes(ErrorTypeEnum.SESSION)) {
          publish(CustomEventEnum.SESSION_EXPIRED, message);
        }
      });

      span.end();
    }

    if (networkError) {
      span.recordException({
        message: `[Network error]: ${networkError}`,
      });
      span.end();
    }
  },
);
