import { gql } from '@apollo/client';

import {
  FRAGMENT_DOCUMENT_ELEMENTS,
  FRAGMENT_DOCUMENT_INTERACTIONS,
  FRAGMENT_DOCUMENT_TEMPLATE,
} from '@/app/(document)/queries';

export const apolloFragments = gql`
  ${FRAGMENT_DOCUMENT_ELEMENTS}
  ${FRAGMENT_DOCUMENT_TEMPLATE}
  ${FRAGMENT_DOCUMENT_INTERACTIONS}
`;
