'use client';

import { useEffect, useState } from 'react';

import CardInfo from '../CardInfo';

export function LanguageDetector() {
  const [isPageTranslated, setIsPageTranslated] = useState(false);

  useEffect(() => {
    const { documentElement } = document;

    const detectLanguage = ([mutation]: MutationRecord[]) => {
      const lang = (mutation.target as HTMLElement).getAttribute('lang');
      setIsPageTranslated(lang !== 'en');
    };

    const observer = new MutationObserver(detectLanguage);

    observer.observe(documentElement, {
      attributes: true,
      attributeFilter: ['lang'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  if (isPageTranslated) {
    return (
      <div className='fixed top-4 w-full max-w-screen z-[200] px-4'>
        <CardInfo.Root className='mx-auto w-max max-w-full lg:max-w-5xl flex-col items-start shadow-sm'>
          <CardInfo.Title
            className='whitespace-nowrap self-start'
            title='Page translated'
          />
          <CardInfo.Description description='Translating the page may lead to inaccuracies or errors, and might not fully capture the original context of the text. For an optimal experience, we recommend reverting the language setting back to English.' />

          <CardInfo.Action
            className='self-end'
            onClick={() => setIsPageTranslated(false)}
          >
            Understood
          </CardInfo.Action>
        </CardInfo.Root>
      </div>
    );
  }

  return null;
}
