import { ToastClose } from './ToastClose';
import { ToastContent } from './ToastContent';
import { ToastRoot } from './ToastRoot';

const ToastComponent = {
  Root: ToastRoot,
  Content: ToastContent,
  Close: ToastClose,
};

export default ToastComponent;
