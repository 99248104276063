export enum RevalidateTagEnum {
  ACCOUNT = 'account',
  ASSETS = 'assets',
  CLIENT = 'client',
  DOCUMENT_ELEMENTS = 'documentElements',
  FILE_SHARE = 'fileShare',
  LOCATIONS = 'locations',
  STEP_ASSIGNEES = 'stepAssignees',
  SUBSCRIPTION = 'subscription',
  TRANSACTION = 'transaction',
  WEBHOOKS = 'webhooks',
  ZUP = 'zup',
  ZUPS = 'zups',
  ITEMS = 'items',
  GUEST_ZUP_STEPS = 'guestZupSteps',
  GUEST_ZUP_STEP = 'guestZupStep',
  GUEST_STEP_DOCUMENT = 'guestStepDocument',
  GUEST_ITEM_TRAIL = 'guestItemTrail',
  GUEST_ZUP_STEPS_NAV = 'guestZupStepsNav',
  GUEST_ITEMS = 'guestItems',
  CONTRACTS = 'contracts',
  CONTRACT_TEMPLATES = 'contractTemplates',
  CONTRACT_TEMPLATE = 'contractTemplate',
  CONTRACT = 'contract',
  MEMBERS = 'members',
  INVITE = 'invite',
  ORGANIZATION = 'organization',
  GUEST_ZUP_ITEM = 'guestZupItem',
  ZUP_MEMBER = 'zupMember',
  PLANS = 'plans',
  START_STEP = 'startStep',
  ZUP_STEPS = 'zupSteps',
}

export enum ScreenWidthEnum {
  SM = 640,
  MD = 768,
  LG = 1024,
}

export enum UserTypeEnum {
  ZUPPER = 'ZUPPER',
  GUEST = 'GUEST',
}

export enum CredentialProviderEnum {
  CREDENTIALS = 'credentials',
  ACCESS_CODE = 'access-code',
}

export enum RoleAccessEnum {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
}

export enum ErrorTypeEnum {
  SESSION = `Session expired`,
  NOT_MEMBER = `User is not a member of this zup`,
  GUEST_EXISTS = `Guest already exists`,
  PERMISSION = `User does not have permission to access this resource`,
  PLAN_UPGRADE_NEDDED = `PLAN_UPGRADE_NEEDED`,
}
